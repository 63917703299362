import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { QaroniIntermediate } from '@qaroni-core/types/qaroni-intermediate/qaroni-intermediate';
import { BehaviorSubject, Observable, shareReplay, Subject } from 'rxjs';
import { OAuthApiResponse, UserApiResponse } from '../types/auth-response';
import {
  ChangePasswordJson,
  ForgotDTO,
  LoginDTO,
  OtpDTO,
  OtpUsernameJson,
  ResetPasswordJson,
  SignUpJson,
  UserDataRegisterDTO,
} from '../types/authentication';
import { IAE } from '../types/iae';
import { IaeApiResponse } from '../types/iaes-response';
import { OAuth } from '../types/o-auth';
import { User } from '../types/user';
import { OAuthErrorsService } from './o-auth-errors.service';
import { OAuthHttpService } from './o-auth-http.service';
import { OAuthSnackbarsService } from './o-auth-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthService extends QaroniIntermediate {
  protected readonly oAuthSubject = new Subject<OAuth>();
  protected readonly userSubject = new Subject<User>();
  protected readonly iaesSubject = new Subject<IAE[]>();
  protected readonly zIndexSubject = new BehaviorSubject<boolean>(false);

  private oAuthHttp = inject(OAuthHttpService);
  private snackbars = inject(OAuthSnackbarsService);
  private oAuthErrors = inject(OAuthErrorsService);

  get getUserID(): number {
    return this.storage.getUserID;
  }

  get getClientID(): number {
    return this.storage.getClientID;
  }

  get getMerchantID(): number {
    return this.storage.getMerchantID;
  }

  get zIndex$(): Observable<boolean> {
    return this.zIndexSubject.asObservable().pipe(shareReplay(1));
  }

  public hideZindex(): void {
    this.zIndexSubject.next(false);
  }

  public showZIndex(): void {
    this.zIndexSubject.next(true);
  }

  // ==========================================================================================

  public getUser$() {
    return this.userSubject.asObservable().pipe(shareReplay(1));
  }

  public register(signUpJSON: SignUpJson): void {
    this.oAuthHttp
      .register$(signUpJSON)
      .subscribe({ next: this.nextRegister, error: this.errorRegister });
  }

  private nextRegister = (data: UserApiResponse): void => {
    if (this.status201(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorRegister = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorRegister(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public validate(userID: number | string, otp: OtpDTO): void {
    this.oAuthHttp
      .validate$(userID, otp)
      .subscribe({ next: this.nextValidate, error: this.errorValidate });
  }

  private nextValidate = (data: OAuthApiResponse): void => {
    if (this.status200(data)) {
      const oAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
      this.snackbars.successValidate();
    } else {
      this.oAuthSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorValidate = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorValidate(error);
    this.oAuthSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public getOAuth$() {
    return this.oAuthSubject.asObservable().pipe(shareReplay(1));
  }

  public login(login: LoginDTO): void {
    this.oAuthHttp
      .login$(login)
      .subscribe({ next: this.nextLogin, error: this.errorLogin });
  }

  private nextLogin = (data: OAuthApiResponse): void => {
    if (this.status200(data)) {
      const oAuth = data.body.result[0];
      this.storage.set(oAuth);
      this.oAuthSubject.next(oAuth);
    } else {
      this.oAuthSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorLogin = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorLogin(error, this.messageSubject);
    this.oAuthSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public forgot(forgot: ForgotDTO): void {
    this.oAuthHttp
      .forgot$(forgot)
      .subscribe({ next: this.nextForgot, error: this.errorForgot });
  }

  private nextForgot = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successForgot();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorForgot = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorForgot(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public resetPassword(
    userID: number | string,
    resetPasswordJSON: ResetPasswordJson
  ): void {
    this.oAuthHttp.resetPassword$(userID, resetPasswordJSON).subscribe({
      next: this.nextResetPassword,
      error: this.errorResetPassword,
    });
  }

  private nextResetPassword = (data: UserApiResponse): void => {
    if (this.status201(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successResetPassword();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorResetPassword = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public otp(userID: number | string): void {
    this.oAuthHttp
      .otp$(userID)
      .subscribe({ next: this.nextOtp, error: this.errorOtp });
  }

  private nextOtp = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successSentNewConfirmationCode();
    } else {
      this.snackbars.failureSentNewConfirmationCode();
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorOtp = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorOtp(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public otpUsername(otpUsernameJSON: OtpUsernameJson): void {
    this.oAuthHttp
      .otpUsername$(otpUsernameJSON)
      .subscribe({ next: this.nextOtpUsername, error: this.errorOtpUsername });
  }

  private nextOtpUsername = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorOtpUsername = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public changePassword(changePasswordJSON: ChangePasswordJson): void {
    if (!(this.hasOAuth && this.storage.getUserID)) {
      return;
    }

    this.oAuthHttp
      .changePassword$(this.storage.getUserID, changePasswordJSON)
      .subscribe({
        next: this.nextChangePassword,
        error: this.errorChangePassword,
      });
  }

  private nextChangePassword = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
      this.snackbars.successResetPassword();
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorChangePassword = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorChangePassword(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public getUserRegister(userID: number | string): void {
    this.oAuthHttp.getUserRegister$(userID).subscribe({
      next: this.nextGetUserRegister,
      error: this.errorGetUserRegister,
    });
  }

  private nextGetUserRegister = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorGetUserRegister = (error: HttpErrorResponse): void => {
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public updateUserRegister(
    userID: number | string,
    userDataRegister: UserDataRegisterDTO
  ): void {
    this.oAuthHttp.updateUserRegister$(userID, userDataRegister).subscribe({
      next: this.nextUpdateUserRegister,
      error: this.errorUpdateUserRegister,
    });
  }

  private nextUpdateUserRegister = (data: UserApiResponse): void => {
    if (this.status200(data)) {
      const user = data.body.result[0];
      this.userSubject.next(user);
    } else {
      this.userSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorUpdateUserRegister = (error: HttpErrorResponse): void => {
    this.oAuthErrors.errorUpdateUserRegister(error);
    this.userSubject.next(null);
    this.errors.communication(error);
  };

  // ==========================================================================================

  public getIAEs$() {
    return this.iaesSubject.asObservable().pipe(shareReplay(1));
  }

  public getIAEs(): void {
    this.oAuthHttp
      .getIAEs$()
      .subscribe({ next: this.nextGetIAEs, error: this.errorGetIAEs });
  }

  private nextGetIAEs = (data: IaeApiResponse): void => {
    if (this.status200(data)) {
      const iaes = data.body.result;
      this.iaesSubject.next(iaes);
    } else {
      this.iaesSubject.next(null);
      this.errors.apiInvalidResponse(data);
    }
  };

  private errorGetIAEs = (error: HttpErrorResponse): void => {
    this.iaesSubject.next(null);
    this.errors.communication(error);
  };
}
