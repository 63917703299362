import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ErrorsHttpService } from '@qaroni-core/services/comms/errors-http/errors-http.service';
import { Subject } from 'rxjs';
import { OAuthSnackbarsService } from './o-auth-snackbars.service';

@Injectable({
  providedIn: 'root',
})
export class OAuthErrorsService {
  private errorsHttp = inject(ErrorsHttpService);
  private snackbars = inject(OAuthSnackbarsService);

  errorRegister(error: HttpErrorResponse) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0030')) {
      this.snackbars.failureRegisterEmailExists();
    } else if (this.errorsHttp.isErrorCode(error, 'E0031')) {
      this.snackbars.failureRegisterPhoneExists();
    } else if (this.errorsHttp.isErrorCode(error, 'E0032')) {
      this.snackbars.failureRegisterNIFExists();
    } else if (this.errorsHttp.isErrorCode(error, 'E0004')) {
      if (
        error.status === 400 &&
        error.error['errors']?.[0]?.['detail'] === 'Invalid recaptcha'
      ) {
        this.snackbars.failureRegisterReCaptcha();
      } else {
        this.snackbars.failureRegisterExists();
      }
    }
  }

  errorValidate(error: HttpErrorResponse) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0008')) {
      this.snackbars.failureForgotRole();
    } else if (this.errorsHttp.isErrorCode(error, 'E0010')) {
      this.snackbars.failureValidate();
    }
  }

  errorLogin(error: HttpErrorResponse, messageSubject: Subject<string>) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0012')) {
      this.snackbars.failureLogin();
    } else if (this.errorsHttp.isErrorCode(error, 'E0013')) {
      this.snackbars.failureLoginCancelled();
    } else if (this.errorsHttp.isErrorCode(error, 'E0014')) {
      this.snackbars.failureLoginInactive();
    } else if (this.errorsHttp.isErrorCode(error, 'E0015')) {
      this.snackbars.failureCreateAccount();
    } else if (this.errorsHttp.isErrorCode(error, 'E0020')) {
      messageSubject.next(error?.error?.errors[0]?.code);
    }
  }

  errorForgot(error: HttpErrorResponse) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0008')) {
      this.snackbars.failureForgotRole();
    } else if (this.errorsHttp.isErrorCode(error, 'E0012')) {
      this.snackbars.failureForgotYouAreNotInTheCorrectApp();
    } else if (this.errorsHttp.isErrorCode(error, 'E0013')) {
      this.snackbars.failureRecoverPassworAccountCancelled();
    } else if (this.errorsHttp.isErrorCode(error, 'E0014')) {
      this.snackbars.failureRecoverPassworAccountInactive();
    } else if (this.errorsHttp.isErrorCode(error, 'E0020')) {
      this.snackbars.failureRecoverPassworAccountCreated();
    } else if (this.errorsHttp.isErrorCode(error, 'E0022')) {
      this.snackbars.failureRecoverPassworAccountPending();
    }
  }

  errorOtp(error: HttpErrorResponse) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0013')) {
      this.snackbars.failureSentNewConfirmationCodeCancelled();
    } else if (this.errorsHttp.isErrorCode(error, 'E0014')) {
      this.snackbars.failureSentNewConfirmationCodeInactive();
    } else {
      this.snackbars.failureSentNewConfirmationCode();
    }
  }

  errorChangePassword(error: HttpErrorResponse) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0010')) {
      this.snackbars.failureChangePasswordOldPassword();
    } else if (this.errorsHttp.isErrorCode(error, 'E0004')) {
      this.snackbars.failureChangePasswordSame();
    } else {
      this.snackbars.failureChangePassword();
    }
  }

  errorUpdateUserRegister(error: HttpErrorResponse) {
    if (!this.errorsHttp.isControlledError(error)) {
      return;
    }

    if (this.errorsHttp.isErrorCode(error, 'E0006')) {
      this.snackbars.failureChangePhone();
    }
  }
}
