import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OAuthSnackbars } from '../types/o-auth-snackbars.config';

@Injectable({
  providedIn: 'root',
})
export class OAuthSnackbarsService {
  private snackbar = inject(MatSnackBar);
  private translate = inject(TranslateService);

  successRegister() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successRegister.message),
      OAuthSnackbars.successRegister.closeBtn,
      OAuthSnackbars.successRegister.config
    );
  }

  failureRegisterEmailExists() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureRegisterEmailExists.message),
      OAuthSnackbars.failureRegisterEmailExists.closeBtn,
      OAuthSnackbars.failureRegisterEmailExists.config
    );
  }

  failureRegisterPhoneExists() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureRegisterPhoneExists.message),
      OAuthSnackbars.failureRegisterPhoneExists.closeBtn,
      OAuthSnackbars.failureRegisterPhoneExists.config
    );
  }

  failureRegisterNIFExists() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureRegisterNIFExists.message),
      OAuthSnackbars.failureRegisterNIFExists.closeBtn,
      OAuthSnackbars.failureRegisterNIFExists.config
    );
  }

  failureRegisterExists() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureRegisterExists.message),
      OAuthSnackbars.failureRegisterExists.closeBtn,
      OAuthSnackbars.failureRegisterExists.config
    );
  }

  failureRegisterReCaptcha() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureRegisterReCaptcha.message),
      OAuthSnackbars.failureRegisterReCaptcha.closeBtn,
      OAuthSnackbars.failureRegisterReCaptcha.config
    );
  }

  successValidate() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successValidate.message),
      OAuthSnackbars.successValidate.closeBtn,
      OAuthSnackbars.successValidate.config
    );
  }

  failureForgotRole() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureForgotRole.message),
      OAuthSnackbars.failureForgotRole.closeBtn,
      OAuthSnackbars.failureForgotRole.config
    );
  }

  failureValidate() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureValidate.message),
      OAuthSnackbars.failureValidate.closeBtn,
      OAuthSnackbars.failureValidate.config
    );
  }

  failureLogin() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLogin.message),
      OAuthSnackbars.failureLogin.closeBtn,
      OAuthSnackbars.failureLogin.config
    );
  }

  failureLoginCancelled() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginCancelled.message),
      OAuthSnackbars.failureLoginCancelled.closeBtn,
      OAuthSnackbars.failureLoginCancelled.config
    );
  }

  failureLoginInactive() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginInactive.message),
      OAuthSnackbars.failureLoginInactive.closeBtn,
      OAuthSnackbars.failureLoginInactive.config
    );
  }

  failureLoginPending() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureLoginPending.message),
      OAuthSnackbars.failureLoginPending.closeBtn,
      OAuthSnackbars.failureLoginPending.config
    );
  }

  successForgot() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successForgot.message),
      OAuthSnackbars.successForgot.closeBtn,
      OAuthSnackbars.successForgot.config
    );
  }

  failureForgotYouAreNotInTheCorrectApp() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureForgotYouAreNotInTheCorrectApp.message
      ),
      OAuthSnackbars.failureForgotYouAreNotInTheCorrectApp.closeBtn,
      OAuthSnackbars.failureForgotYouAreNotInTheCorrectApp.config
    );
  }

  failureRecoverPassworAccountCancelled() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureRecoverPassworAccountCancelled.message
      ),
      OAuthSnackbars.failureRecoverPassworAccountCancelled.closeBtn,
      OAuthSnackbars.failureRecoverPassworAccountCancelled.config
    );
  }

  failureRecoverPassworAccountInactive() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureRecoverPassworAccountInactive.message
      ),
      OAuthSnackbars.failureRecoverPassworAccountInactive.closeBtn,
      OAuthSnackbars.failureRecoverPassworAccountInactive.config
    );
  }

  failureRecoverPassworAccountCreated() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureRecoverPassworAccountCreated.message
      ),
      OAuthSnackbars.failureRecoverPassworAccountCreated.closeBtn,
      OAuthSnackbars.failureRecoverPassworAccountCreated.config
    );
  }

  failureRecoverPassworAccountPending() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureRecoverPassworAccountPending.message
      ),
      OAuthSnackbars.failureRecoverPassworAccountPending.closeBtn,
      OAuthSnackbars.failureRecoverPassworAccountPending.config
    );
  }

  failureSentNewConfirmationCodeCancelled() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureSentNewConfirmationCodeCancelled.message
      ),
      OAuthSnackbars.failureSentNewConfirmationCodeCancelled.closeBtn,
      OAuthSnackbars.failureSentNewConfirmationCodeCancelled.config
    );
  }

  failureSentNewConfirmationCodeInactive() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureSentNewConfirmationCodeInactive.message
      ),
      OAuthSnackbars.failureSentNewConfirmationCodeInactive.closeBtn,
      OAuthSnackbars.failureSentNewConfirmationCodeInactive.config
    );
  }

  successResetPassword() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.successResetPassword.message),
      OAuthSnackbars.successResetPassword.closeBtn,
      OAuthSnackbars.successResetPassword.config
    );
  }

  failureChangePasswordOldPassword() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureChangePasswordOldPassword.message
      ),
      OAuthSnackbars.failureChangePasswordOldPassword.closeBtn,
      OAuthSnackbars.failureChangePasswordOldPassword.config
    );
  }

  failureChangePasswordSame() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePasswordSame.message),
      OAuthSnackbars.failureChangePasswordSame.closeBtn,
      OAuthSnackbars.failureChangePasswordSame.config
    );
  }

  failureChangePassword() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePassword.message),
      OAuthSnackbars.failureChangePassword.closeBtn,
      OAuthSnackbars.failureChangePassword.config
    );
  }

  failureChangePhone() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureChangePhone.message),
      OAuthSnackbars.failureChangePhone.closeBtn,
      OAuthSnackbars.failureChangePhone.config
    );
  }

  successSentNewConfirmationCode() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.successSentNewConfirmationCode.message
      ),
      OAuthSnackbars.successSentNewConfirmationCode.closeBtn,
      OAuthSnackbars.successSentNewConfirmationCode.config
    );
  }

  failureSentNewConfirmationCode() {
    this.snackbar.open(
      this.translate.instant(
        OAuthSnackbars.failureSentNewConfirmationCode.message
      ),
      OAuthSnackbars.failureSentNewConfirmationCode.closeBtn,
      OAuthSnackbars.failureSentNewConfirmationCode.config
    );
  }

  failureCreateAccount() {
    this.snackbar.open(
      this.translate.instant(OAuthSnackbars.failureCreateAccount.message),
      OAuthSnackbars.failureCreateAccount.closeBtn,
      OAuthSnackbars.failureCreateAccount.config
    );
  }
}
